import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { LineSpacer } from '../components/atm.line-spacer/line-spacer.styled';
import { CompanyCard } from '../components/mol.company-card/company-card.component';
import { Spacing } from '../components/obj.constants/constants';

import Seo from '../components/seo';

const NotFoundPage = () => (
  <>
    <Seo title="404: Página não encontrada" />
    <Box
      paddingTop={Spacing.XLarge}
      paddingBottom={Spacing.XLarge}
      paddingLeft={Spacing.Large}
      paddingRight={Spacing.Large}
    >
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={1} lg={2} />
        <Grid item xs={10} lg={8}>
          <Typography variant="h1">Página não encontrada</Typography>
        </Grid>
        <Grid item xs={1} lg={2} />
      </Grid>
    </Box>
  </>
);

export default NotFoundPage;
